import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Intro from '../../components/organisms/intro/Intro'
import Logomark from '../../components/Logomark/Logomark'
import Hr from '../../components/Hr/Hr'
import { Helmet } from 'react-helmet'
import { LoginContext } from '../../lib/utils'
import { fetchSubscriptions } from '../../api/mailchimp'
import * as SignUpStyles from '../../components/layoutComponents/signUp/SignUp.module.scss'
import { MULTISITE_COUNTIES } from '../../lib/data/Sites'
import { savePreferences } from '../../api/signup'
import { navigate } from 'gatsby'
import LoadingLogomark from '../../components/LoadingLogomark/LoadingLogomark'

interface CountyError {
  name: string
  id: number
  status: number | string
  message: string | undefined
}

interface County {
  apiKey: string
  id: number
  name: string
  status: 'subscribed' | 'unsubscribed' | number
}

interface CountyResItem {
  action: 'subscribe' | 'unsubscribe'
  county_id: number
  county_name: string
  result: {
    status: 'subscribed' | 'unsubscribed' | number
  }
}

interface CountyRes {
  data: CountyResItem[]
}

const ManageSubscriptions = () => {
  const { usr } = useContext(LoginContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string | boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [initialCounties, setInitialCounties] = useState<number[]>([])
  const [subscribedCounties, setSubscribedCounties] = useState<number[]>([])
  const [individualErrors, setIndividualErrors] = useState<
    CountyError[] | undefined
  >(undefined)

  const fetchData = async (email: string) => {
    setLoading(true)
    setSubscribedCounties([])
    setInitialCounties([])
    try {
      const subs: County[] = await fetchSubscriptions(email)
      const subbedTo =
        subs.length > 0 && subs.filter(c => c.status === 'subscribed')
      if (subbedTo) {
        setSubscribedCounties(subbedTo.map(c => c.id))
        setInitialCounties(subbedTo.map(c => c.id))
      }
      setLoading(false)
    } catch (e) {
      setError(e as string)
      setLoading(false)
    }
  }

  const handleInputChange = (change: ChangeEvent<HTMLInputElement>) => {
    const status: boolean = change.target.checked
    setSubmitted(false)
    const thisValue: number = parseInt(change.target.value)

    if (status) {
      const counties = subscribedCounties
      setSubscribedCounties([thisValue, ...counties])
    } else {
      const counties = subscribedCounties.filter(c => c != thisValue)
      setSubscribedCounties(counties)
    }
  }

  const handleFormSubmission = () => {
    setSubmitting(true)
    savePreferences({
      counties: subscribedCounties,
      email: usr?.viewer.email,
      initialCounties: initialCounties
    })
      .then(res => {
        if (res.success === 0) {
          setIndividualErrors([])
          setSubmitting(false)
          throw new Error(res.message)
        }

        const { data: counties }: CountyRes = res
        const individualErrorArray: CountyError[] = counties
          .map((county: CountyResItem) => {
            const name = county.county_name.replace('&amp;', '&')
            let message
            if (county.action === 'subscribe' && county.result.status !== 400) {
              message =
                county.result.status === 401
                  ? `There was a problem signing up to the ${name} Newsletter, please try again or <a href="mailto:hello@muddystilettos.co.uk" target="_BLANK">email us</a> for assistance.`
                  : `You have successfully subscribed to the ${name} Newsletter`
            } else if (county.action === 'unsubscribe') {
              message =
                county.result.status === 'unsubscribed'
                  ? `You have successfully unsubscribed from the ${name} Newsletter`
                  : `There was a problem unsubscribing from the ${name} Newsletter, please try again or <a href="mailto:hello@muddystilettos.co.uk" target="_BLANK">email us</a> for assistance.`
            }
            return {
              name: name,
              id: county.county_id,
              status: county.result.status,
              message: message ?? undefined
            }
          })
          .sort((a: any, b: any) =>
            a.name < b.name ? -1 : a.name > b.name ? 1 : 0
          )
        setIndividualErrors(individualErrorArray)
        setSubmitting(false)
        setSubmitted(true)

        let validCounties: number[] = initialCounties
        counties.forEach((county: CountyResItem) => {
          const {
            county_id,
            action,
            result: { status }
          } = county
          if (action === 'subscribe' && status !== 401) {
            if (validCounties.indexOf(county_id) < 0)
              validCounties.push(county_id)
          }
          if (action === 'unsubscribe' && status === 'unsubscribed') {
            if (validCounties.indexOf(county_id) > -1) {
              validCounties = validCounties.filter(c => c !== county_id)
            }
          }
        })

        setInitialCounties(validCounties)
        setSubscribedCounties(validCounties)
      })
      .catch(e => {
        setError(e as string)
      })
  }

  useEffect(() => {
    if (usr?.viewer && usr?.viewer.email) {
      fetchData(usr.viewer.email)
    }
  }, [usr])

  return (
    <>
      <Helmet>
        <title>Manage your subscriptions | Muddy Stilettos</title>
      </Helmet>
      <Header />
      <Intro removeMargin={true}>
        <Logomark />
        <h1>Manage your subscriptions</h1>
        <p>
          Choose which county you’d like to receive emails from. You can
          unsubscribe at any time.
        </p>
      </Intro>
      <div>
        {loading && (
          <div style={{ textAlign: 'center' }}>
            <LoadingLogomark show={true} complete={false} />
            <p className={SignUpStyles.Loading}>
              Loading your subscriptions...
            </p>
          </div>
        )}
        {error && <p className={'error'}>{error}</p>}
        {!loading && !usr?.viewer?.email && (
          <div className={SignUpStyles.Form}>
            <p className={SignUpStyles.NotLoggedInMessage}>
              You need to be logged in to manage your subscriptions.
            </p>
            <p className={SignUpStyles.ButtonRow}>
              <button type="button" onClick={() => navigate('/log-in')}>
                Go to Log In
              </button>
            </p>
          </div>
        )}
        {!loading && usr?.viewer?.email && (
          <div
            className={`${SignUpStyles.Wrapper} ${SignUpStyles.PaddingNone}`}
          >
            <form className={SignUpStyles.Form}>
              <p>
                <span className={SignUpStyles.CountySelectorWrap}>
                  {MULTISITE_COUNTIES.filter(
                    c => c.id !== 6 && c.id !== 16 && c.id !== 1
                  )
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map(({ id, title }) => (
                      <span key={id} className={SignUpStyles.CountySelector}>
                        <input
                          type={`checkbox`}
                          id={id.toString()}
                          value={id}
                          name={`locations`}
                          checked={
                            subscribedCounties.find(c => c == id) ? true : false
                          }
                          onChange={handleInputChange}
                        />
                        <span>
                          <label
                            className={SignUpStyles.SubscriptionLabels}
                            htmlFor={id.toString()}
                          >
                            {title}
                          </label>
                        </span>
                        {id > 100 && id < 150 && (
                          <span
                            className={SignUpStyles.ComingSoon}
                            title={`You can sign up for ${title} now, the newsletter and local site are coming soon!`}
                          >
                            Coming soon
                          </span>
                        )}
                      </span>
                    ))}
                </span>
              </p>
              <p className={SignUpStyles.ButtonRow}>
                <button
                  type="button"
                  onClick={() => handleFormSubmission()}
                  disabled={submitted || submitting}
                >
                  {submitting ? 'Submitting...' : 'Save Preferences'}
                </button>
                {error && (
                  <span
                    className={SignUpStyles.Alert}
                    dangerouslySetInnerHTML={{
                      __html: typeof error === 'string' ? error : ''
                    }}
                  />
                )}
                {submitted && (
                  <span
                    className={SignUpStyles.Alert}
                  >{`Your settings have been saved.${
                    individualErrors && individualErrors.length
                      ? ' Please see below for any messages regarding your preferences.'
                      : ''
                  }`}</span>
                )}
              </p>
              {individualErrors && individualErrors.length && (
                <ul className={SignUpStyles.ErrorWrapper}>
                  {individualErrors.map((e: any) => (
                    <li
                      key={e.id}
                      dangerouslySetInnerHTML={{ __html: e.message }}
                      aria-hidden={e.status == 400}
                    />
                  ))}
                </ul>
              )}
            </form>
          </div>
        )}
      </div>
      <Hr />
      <Footer />
    </>
  )
}

export default ManageSubscriptions
